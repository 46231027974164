import { urlSafeDecode, urlSafeEncode } from '@aws-amplify/core';
import type { ProductFeature } from '@samsonvt/shared-types/productsTable';

export const tabLink = (productId: string, tabName: ProductFeature, ...args: (string | undefined)[]) =>
  ['/product-library', productId, tabName, ...args].filter(Boolean).join('/');

export const workInstructionsLink = (productId: string, name?: string, action?: string, step?: string) =>
  tabLink(productId, 'work-instructions', name, action, step);

const trailingSlashRegex = /\/+$/;
const startingSlashRegex = /^\/+/;

export const combineUrls = (baseUrl: string, relativeUrl?: string) =>
  relativeUrl ? `${baseUrl.replace(trailingSlashRegex, '')}/${relativeUrl.replace(startingSlashRegex, '')}` : baseUrl;

export const productCategoryPathEnumerationSeparator = '.';

export const decodeProductCategoryPathEnumeration = (productCategoryPathEnumeration: string) => {
  const nodes = productCategoryPathEnumeration.split(productCategoryPathEnumerationSeparator);
  const decodedNodes = nodes.map((node) => urlSafeDecode(node));
  return decodedNodes.join(productCategoryPathEnumerationSeparator);
};

export const encodeProductCategoryPathEnumeration = (productCategoryPathEnumeration: string) => {
  const nodes = productCategoryPathEnumeration.split(productCategoryPathEnumerationSeparator);
  const decodedNodes = nodes.map((node) => urlSafeEncode(node));
  return decodedNodes.join(productCategoryPathEnumerationSeparator);
};

export const encodeProductCategoryName = (productCategoryName: string) => urlSafeEncode(productCategoryName);

export const authorizationCodeRedirectUrl = `${window.location.protocol}//${
  window.location.host === 'localhost' ? 'localhost:3000' : window.location.host
}/authorization-code/`;
