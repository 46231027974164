import { CognitoUser } from '@aws-amplify/auth';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { patchUser } from 'src/services/api';
import { determineCurrencyToUse } from 'src/services/formatCurrency';
import useShoppingCartMutation from '../ShoppingCart/useShoppingCartMutation';

export interface AccountDetails {
  name: string;
  telephone: string;
  email: string;
  companyName: any;
  buildingName: string;
  streetName: string;
  city: string;
  country: string;
  postalCode: string;
  currency: ISO4217;
}

type RawCognitoUser = Record<'attributes', RawCognitoUserAttributes>;
type RawCognitoUserAttributes = Record<string, string>;

export type MutateAccountDetailsInput = Partial<{
  name: string;
  companyName: string;
  telephone: string;
  buildingName: string;
  streetName: string;
  city: string;
  postalCode: string;
  country: string;
  currency: ISO4217;
}>;

export interface MutateAccountDetails {
  applyUserDetails: UseMutateFunction<void, unknown, MutateAccountDetailsInput, unknown>;
  isApplyUserDetailsSuccess: boolean;
  isApplyUserLoading: boolean;
  isApplyUserDetailsError: boolean;
  applyUserDetailsError: unknown;
  resetUserDetailsMutation: () => void;
}

export function getUserDetails(
  cognitoUser: CognitoUser | null,
  preferredCurrency: ISO4217 | undefined,
  currencies: ISO4217[] | undefined
): AccountDetails {
  if (!(cognitoUser as any as RawCognitoUser)?.attributes) {
    return {
      name: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      telephone: '',
      email: '',
      companyName: '',
      buildingName: '',
      streetName: '',
      city: '',
      country: '',
      postalCode: '',
      currency: determineCurrencyToUse({ currency: '', currencies, preferredCurrency }),
    };
  }

  const {
    address = '',
    name = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    phone_number = '',
    email = '',
  } = (cognitoUser as any as RawCognitoUser).attributes;

  const currency = determineCurrencyToUse({
    currency: (cognitoUser as any as RawCognitoUser).attributes['custom:vtaas-currency'],
    preferredCurrency,
    currencies,
  });
  const companyName = (cognitoUser as any as RawCognitoUser).attributes['custom:vtaas-company-name'] || '';
  const [buildingName = '', streetName = '', city = '', postalCode = '', country = ''] = (address as string).split(',');

  return {
    name,
    telephone: phone_number,
    email,
    companyName,
    buildingName,
    streetName,
    city,
    country,
    postalCode,
    currency,
  };
}

export function useMutateAccountDetails(
  refreshSession: () => Promise<unknown>,
  cognitoUser: CognitoUser | null,
  preferredCurrency: ISO4217 | undefined,
  currencies: ISO4217[] | undefined
): MutateAccountDetails {
  const username = cognitoUser?.getUsername() || ''; // If no username let the request fail and catch the error with a toast
  const { mutate } = useShoppingCartMutation();
  const prevAccountDetails = getUserDetails(username ? cognitoUser : null, preferredCurrency, currencies);

  // Needs to be partial to allow partial updates
  const applyUserDetailsRequest = async (newAccountDetails: MutateAccountDetailsInput) => {
    const patchUserRequest: MutateAccountDetailsInput = {
      ...prevAccountDetails,
      ...newAccountDetails,
    };

    // username is a better representation than userId. External provider users can only be referenced by username, for normal users username will be equivalent to userId
    await patchUser(username, patchUserRequest);
  };

  const {
    mutate: applyUserDetails,
    isSuccess: isApplyUserDetailsSuccess,
    isLoading: isApplyUserLoading,
    isError: isApplyUserDetailsError,
    error: applyUserDetailsError,
    reset: resetUserDetailsMutation,
  } = useMutation(applyUserDetailsRequest, {
    onSuccess: () => {
      mutate([]); // clear cart on change currency
      refreshSession();
    },
  });

  return {
    applyUserDetails,
    isApplyUserDetailsSuccess,
    isApplyUserLoading,
    isApplyUserDetailsError,
    applyUserDetailsError,
    resetUserDetailsMutation,
  };
}
