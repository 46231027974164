/* eslint-disable class-methods-use-this */
import { CognitoUser } from '@aws-amplify/auth';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { AccountDBSource, AccountTag, FeatureFlag, ProductCategories } from '@samsonvt/shared-types/accountsTable';
import { AccountHeader } from '@samsonvt/shared-types/userLambda';

import { AccountDetails, MutateAccountDetails } from 'src/providers/User/userDetails';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';

const notLoggedIn = 'Not logged in';

type JWTToken = string;
export interface CognitoTokenEndPointResponse {
  access_token: JWTToken;
  id_token: JWTToken;
  refresh_token: JWTToken;
  token_type: 'Bearer';
  expires_in: number;
}

interface Permitted {
  permissions: Record<string, string[]>;
}

export class SamsonVtUser implements Permitted {
  logoUrl?: string;

  user: CognitoUser | null = null;

  permissions: Record<string, string[]> = {};

  discountPercentage: number | undefined = undefined;

  userAccounts: AccountHeader[] = [];

  preferredCurrency: ISO4217 | undefined;

  tags: AccountTag[] | undefined;

  enabledFeatures: FeatureFlag[] = [];

  translations: AccountDBSource['translations'];

  checkoutFormConfig?: AccountDBSource['checkoutFormConfig'];

  productCategories?: ProductCategories;

  userLoading: boolean = true;

  customAttributesNames?: AccountDBSource['customAttributesNames'];

  login: (usernameOrEmail: string, password: string) => Promise<CognitoUser> = () => Promise.reject(notLoggedIn);

  logout: () => Promise<void> = () => Promise.reject(notLoggedIn);

  changePassword: (oldPassword: string, newPassword: string) => Promise<'SUCCESS'> = () => Promise.reject(notLoggedIn);

  createPasswordWithTempCredentials: (
    usernameOrEmail: string,
    tempPassword: string,
    newPassword: string
  ) => Promise<any> = () => Promise.reject(notLoggedIn);

  forgotPassword: (usernameOrEmail: string) => Promise<CognitoUser> = () => Promise.reject(notLoggedIn);

  forgotPasswordSubmit: (usernameOrEmail: string, code: string, newPassword: string) => Promise<CognitoUser> = () =>
    Promise.reject(notLoggedIn);

  disableMfa: () => Promise<string> = () => Promise.reject(notLoggedIn);

  signup!: (usernameOrEmail: string, password: string, name: string, surname: string) => Promise<ISignUpResult>;

  confirmSignup!: (uidUsername: string, code: string) => Promise<any>;

  refreshSession!: () => Promise<unknown>;

  userDetails!: MutateAccountDetails & AccountDetails;

  initiateCognitoSession!: (sessionTokens: CognitoTokenEndPointResponse) => CognitoUser;
}
